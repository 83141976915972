import $ from 'jquery';

const frontfoot = name => {
  $(document).ready(function(){
    $('.j-emailsubmit').submit(function(event){
      event.preventDefault();
      var email = $('.j-emailfield').val();      
      var list = $('.j-emailsubmit').data('list');
      var path = "?&email="+email+"&list="+list;
      $.ajax({
        url: 'https://edm.aap.com.au/edm' + path,
        type: 'get',
        data: '',
        dataType: 'json',
        success: function(data) {
          $('.j-emailfield').toggleClass('d-none');
          $('.j-emailbutton').toggleClass('d-none');
          $('.j-emailminitext').toggleClass('d-none');
          $('.j-emailsubmit').append('<p class="c-index-cta__frontfoot__text u-margin-top-40">Thanks for signing up! <br>You will now receive the day\'s news agenda directly from AAP.</p>');
        },
        error: function(data) {
          $('.j-emailfield').toggleClass('d-none');
          $('.j-emailbutton').toggleClass('d-none');
          $('.j-emailminitext').toggleClass('d-none');
          $('.j-emailsubmit').append('<p class="c-index-cta__frontfoot__text u-margin-top-40">Something went wrong! <br>Please email us at hello@aapstudio.com.au to let us know.</p>');
        }
			});
    });
  });
};
export default frontfoot;
