import $ from 'jquery';

const card = name => {
  $(document).ready(function(){
    let wrapper = $( ".a-content>h2,.a-excerpt,.j-truncobj" );
    var tolerance = 2;
    // if ($(window).width() < 992){
    //   tolerance = 15;
    // }
    var x = 0;
    
    for (var i=0;i<wrapper.length;i++){
      var options = {
        callback: function( isTruncated ) {
          //console.log('truncating...', this);
        },
        /* Function invoked after truncating the text.
           Inside this function, "this" refers to the wrapper. */
  
        ellipsis: "\u2026 ",
        /* The text to add as ellipsis. */
  
        height: null,
        /* The (max-)height for the wrapper:
           null: measure the CSS (max-)height ones;
           a number: sets a specific height in pixels;
           "watch": re-measures the CSS (max-)height in the "watch". */
  
        keep: null,
        /* Query selector for elements to keep after the ellipsis. */
  
        tolerance: tolerance,
        /* Deviation for the measured wrapper height. */
  
        truncate: "word",
        /* How to truncate the text: "node", "word" or "letter". */
  
        watch: true,
        /* Whether to update the ellipsis: 
           true: Monitors the wrapper width and height;
           "window": Monitors the window width and height. */
      };
      x++;
      var truncItem = $(wrapper[i]);
      var truncClass = 'trunc-'+x;
      truncItem.addClass(truncClass);
      truncItem = $('.'+truncClass)[0];
      if ($(truncItem).is(':visible')){
        //console.log('up to ',truncItem);
        //let dot = new Dotdotdot( truncItem, options );
        //console.log(dot);
        //console.log(dot.API.getInstance());
        // /* Returns the Class instance. */

        // dot.API.truncate();
        // /* Starts the truncate process. */

        // dot.API.restore();
        // /* Restores the original content. */

        // dot.API.destroy();
        // /* Completely restores the wrapper to its pre-init state. */

        //dot.API.watch();
        // /* Starts monitoring the wrapper or window width and height. */

        //dot.API.unwatch();
        /* Stops monitoring the wrapper or window width and height. */
      }
    }
  });
};
export default card;

