/*
  Project: australian-associated-press
  Author: Puck Willis
 */
import $ from 'jquery';
import menu from './modules/menu';
import common from './modules/common';
import frontfoot from './modules/frontfoot';
//import loadmore from './modules/loadmore';
//import login from './modules/login';
import card from './modules/card';
import owl from './modules/owl';
import custom from './modules/custom';
import youtubeoverlay from './modules/youtube-overlay';


$();
menu();
common();
frontfoot();
//loadmore();
//login();
card();
owl();
custom();
youtubeoverlay();