
const custom = name => {
    $(document).ready(function() {
        $('.list-blog.owl-carousel').owlCarousel({
            loop: false,
            margin: 30,
            responsiveClass: true,
            dots: true,
            nav: false,
            autoplay: false,
            responsive: {
                0: {
                    items: 1,
                },
                768: {
                    items: 2,
                },
                992: {
                    items: 3,
                },
                1299: {
                    items: 4,
                }
            }
        });
        $('.site-nav__menu-hamburger').click(function(e){
            $('body').toggleClass('fixed');
        });

		/* loads the youtube js overlay */
		var videoItems = [];
		videoItems = document.getElementsByClassName("video-item");
		if (videoItems.length){
			for (var video of videoItems) {
				var thisitemBuild = new YoutubeOverlayModule({
					sourceUrl: video.getAttribute("data-video"),
					iframeClass: video.getAttribute("data-iframe-class"),
					triggerElement: "#" + video.getAttribute("id"),
					progressCallback: function() {
						console.log("Item activated");
					}
				});
				thisitemBuild.activateDeployment();
			};
		}
    });

    $("#donate .packages ul li").click(function () {
        $("#donate .packages ul li").removeClass("active");
        // $(".tab").addClass("active"); // instead of this do the below
        $(this).addClass("active");
		var formFrequency = $(this).text()
    var donationFrequency;
		if (formFrequency == "Annually") {
			donationFrequency = "annual";
		}
		else if (formFrequency == "Monthly") {
			donationFrequency = "monthly";
		}
		else {
			donationFrequency = "one-time";
		}
    var donateButon = document.getElementById("ppDonateNow");
		donateButon.setAttribute("data-pp-frequency", donationFrequency);
    });

	$("#donate .price ul li").click(function() {
    var customAmmount = document.getElementById("donationCustomAmmount");
    customAmmount.value='';
    $("#donate .price ul li").removeClass("active");
    $(this).addClass("active");
		var donationAmmount = $(this).text().replace('$','');
		var donateButon = document.getElementById("ppDonateNow")
		donateButon.setAttribute("data-pp-amount", donationAmmount);
		if (donateButon.classList.contains("bg-ghost-white")) {
			donateButon.classList.remove("bg-ghost-white");
		}
		if (donateButon.classList.contains("btn-hide")) {
			donateButon.classList.remove("btn-hide");
		}
		donateButon.setAttribute("href", "https://dashboard.presspatron.com/donations/new?frame=1&t=kdZHTKcuqCxTjTUKZtBFN2sY");
	});

	$("#donationCustomAmmount").on('change keydown paste input',function() {
    var customAmmount = document.getElementById("donationCustomAmmount");
		$("#donate .price ul li").removeClass("active");
		var donateButon = document.getElementById("ppDonateNow");
		if (customAmmount.value == '' || customAmmount.value < 2) {
			donateButon.setAttribute("data-pp-amount", 0);
			if (!donateButon.classList.contains("bg-ghost-white")) {
				donateButon.classList.add("bg-ghost-white");
			}
			if (!donateButon.classList.contains("btn-hide")) {
				donateButon.classList.add("btn-hide");
			}
			donateButon.removeAttribute("href");
		}
		else {
			donateButon.setAttribute("data-pp-amount", customAmmount.value);
			if (donateButon.classList.contains("bg-ghost-white")) {
				donateButon.classList.remove("bg-ghost-white");
			}
			if (donateButon.classList.contains("btn-hide")) {
				donateButon.classList.remove("btn-hide");
			}
			donateButon.setAttribute("href", "https://dashboard.presspatron.com/donations/new?frame=1&t=kdZHTKcuqCxTjTUKZtBFN2sY");
		}
	});


};
export default custom;
