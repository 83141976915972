import $ from 'jquery';

const common = name => {
  $(document).ready(function(){
    //console.log('go owl go');
    const pageWindowWidth = $(window).width;
    
    //$('.owl-carousel').owlCarousel({
      //items: 1,
      //nav: true,
      //navText: [$(".j-owl-prev"),$(".j-owl-next")],
    //});
    //$('.owl-carousel').on('changed.owl.carousel', function(event) {
        //var currentItem = event.item.index + 1;
        //$('.j-photocont').removeClass('c-photos-content--out');
        //$('.j-photocont').addClass('c-photos-content--out');
        //$('.j-photocont-'+currentItem).removeClass('c-photos-content--out');
    //});

    $('.j-expitem-select').click(function(){
      
      const toExpand = $(this);
      if (toExpand.hasClass('c-page-about--selected')){
        $('.j-expitem-select').removeClass('c-page-about--selected');
        $('.j-expitem').removeClass('d-none');
        $('.j-expitem').addClass('d-none');
      } else {
        expandItem(toExpand.data('pos'));
      }
    });

    $('.j-profile').click(function(){
      const toExpand = $(this);
      if (toExpand.hasClass('c-profile--selected')){
        $('.j-profile').removeClass('c-profile--selected');
        $('.j-profile-expand').removeClass('d-none');
        $('.j-profile-expand').addClass('d-none');
      } else {
        expandProf('#'+toExpand[0].id);
      }
    });
    $('.j-profile-close').click(function(){
      $('.j-profile').removeClass('c-profile--selected');
      $('.j-profile-expand').removeClass('d-none');
      $('.j-profile-expand').addClass('d-none');
    });

    const expandProf = function(item){
      const expItem = $(item);
      $('.j-profile').removeClass('c-profile--selected');
      $('.j-profile-expand').removeClass('d-none');
      $('.j-profile-expand').addClass('d-none');
      expItem.addClass('c-profile--selected');
      const contOpen = expItem.data('expand');
      $(contOpen).removeClass('d-none');
      window.location.hash = item;
      var y = $(window).scrollTop();  //your current y position on the page
      var winwidth = $(window).width();
      if (winwidth < 768){
        $(window).scrollTop(y-130);
      } else if (winwidth > 767 && winwidth < 991){
        $(window).scrollTop(y-130);
      } else {
        $(window).scrollTop(y-100);
      }
    };

    const expandItem = function(item){
      const posItem = $(item);
      const expItem = $($(posItem.data('item'))[0]);
      $('.j-expitem-select').removeClass('c-page-about--selected');
      $('.j-expitem').removeClass('d-none');
      $('.j-expitem').addClass('d-none');
      expItem.addClass('c-page-about--selected');
      window.location.hash = item;
      const contOpen = expItem.data('item');
      $(contOpen).removeClass('d-none');
      var y = $(window).scrollTop();  //your current y position on the page
      var winwidth = $(window).width();
      //console.log(winwidth);
      if (winwidth < 768){
        $(window).scrollTop(y-100);
      } else if (winwidth > 767 && winwidth < 991){
        $(window).scrollTop(y-100);
      } else {
        
      }
    };
  
    const position = function(){
      const urlHash = window.location.hash;
      if (urlHash){
        expandItem(urlHash); 
      }
    };
    position();
    $('.j-menu-position').click(function(){
      position();
    });
  });
};
export default common;
