import $ from 'jquery';

const menu = name => {
  const pageWindow = $(window);
  const hamBurg = $('.site-nav__menu-hamburger');
  const menuItem = $('.site-menu__item');
  const expandItem = $('.site-sub-menu__expand');
  const underLay = $('.site-menu__overlay');
  if (pageWindow.width() > 991) {
      menuItem.hover(function(){
        $(this).toggleClass('site-menu--selected');
    });
  }
  if (pageWindow.width() < 991) {
    if (hamBurg.length) {
      hamBurg.click(function(){
        //console.log('clicky');
        const mobmenu = $('.site-menu');
        mobmenu.toggleClass('site-menu--open');
        hamBurg.toggleClass('site-menu--selected-hamburger');
        $('.j-moblogo').toggleClass('navigation-logo__img--dark');
        $('.j-header').toggleClass('site-header--dark');
        $('j-menu-item').removeClass('d-none');
        $('.j-search').removeClass('d-none');
        $('.j-searchbar').removeClass('d-none');
        $('.j-searchbar').addClass('d-none');
        underLay.toggleClass('d-none');
        if ($('.site-menu--selected-hamburger')[0]){
          console.log('word');
          $('.j-search').addClass('d-none');
        }
      });
    }
    if (underLay.length) {
      underLay.click(function(){
        //console.log('clicky');
        const mobmenu = $('.site-menu');
        mobmenu.toggleClass('site-menu--open');
        hamBurg.toggleClass('site-menu--selected-hamburger');
        $('.j-moblogo').toggleClass('navigation-logo__img--dark');
        $('.j-header').toggleClass('site-header--dark');
        $('.j-search').removeClass('d-none');
        $('.j-searchbar').removeClass('d-none');
        $('.j-searchbar').addClass('d-none');
        underLay.toggleClass('d-none');
        if ($('.site-menu--selected-hamburger')[0]){
          console.log('word');
          $('.j-search').addClass('d-none');
        }
      });
    }
    expandItem.click(function(){
      const toExpand = $(this);
      const toParent = toExpand[0].parentNode;
      toExpand.toggleClass('site-sub-menu__expanded');
      $(toParent).toggleClass('site-menu--selected');
      $('.j-menu-item').toggleClass('d-none');
      $(toParent).removeClass('d-none');
    });
    $('.j-searchbutton').click(function(){
      $('.j-searchicon').toggleClass('d-none');
      $('.j-searchbar').toggleClass('d-none');
      $('.j-searchicon-cross').toggleClass('d-none');
      const mobmenu = $('.site-menu');
      mobmenu.removeClass('site-menu--open');
      hamBurg.removeClass('site-menu--selected-hamburger');
      $('.j-moblogo').removeClass('navigation-logo__img--dark');
      $('.j-header').removeClass('site-header--dark');
      $('.j-mobham').toggleClass('d-none');
      underLay.removeClass('d-none');
      underLay.addClass('d-none');
    });
    $('.j-discoverbutton').click(function(){
      console.log('click');
      $('.j-discover').toggleClass('d-none');
      $('.j-hamalt-mob').toggleClass('d-none');
      const mobmenu = $('.site-menu');
      mobmenu.removeClass('site-menu--open');
      hamBurg.removeClass('site-menu--selected-hamburger');
      $('.j-moblogo').removeClass('navigation-logo__img--dark');
      $('.j-header').removeClass('site-header--dark');
      $('.j-search').removeClass('d-none');
      $('.j-searchbar').removeClass('d-none');
      $('.j-searchbar').addClass('d-none');
      underLay.removeClass('d-none');
      underLay.addClass('d-none');
    });
  } else {
    $('.j-searchbutton').click(function(){
      $('.j-searchicon').toggleClass('d-none');
      $('.j-searchicon-cross').toggleClass('d-none');
      $('.j-searchbar').toggleClass('d-none');
      $('body').removeClass('u-noscroll');
    });
    $('.j-discoverbutton').click(function(){
      // console.log('click');
      $('.j-discover').toggleClass('d-none');
      $('.j-hamalt-mob').toggleClass('d-none');
      const mobmenu = $('.site-menu');
      mobmenu.removeClass('site-menu--open');
      hamBurg.removeClass('site-menu--selected-hamburger');
      $('.j-moblogo').removeClass('navigation-logo__img--dark');
      $('.j-header').removeClass('site-header--dark');
      $('body').toggleClass('u-noscroll');
    });
    $('.j-sidemenu').click(function(){
      $('.j-sidemenu').toggleClass('c-page-sidemenu__dots--selected');
      $('.j-sidemenu-menu').toggleClass('d-none');
    });
  }

  $(".j-menu-item>a[href='"+window.location.href+"'].site-menu__link").addClass('site-menu__item--current');
  $(".site-sub-menu__item>a[href='"+window.location.href+"'].site-sub-menu__link").addClass('site-menu__item--current');
  $(".site-sub-menu__item>a[href='"+window.location.href+"'].site-sub-menu__link").closest('.site-menu__item').find('.site-menu__link').addClass('site-menu__item--current');
};
export default menu;
